import React from "react"
// import {Link} from 'gatsby';

import Layout from "../components/layout"
import SEO from "../components/seo"
import ImageContentBlock from "../components/imageContentBlock/imageContentBlock"
import FullWidth from "../components/fullWidth/fullWidth"
// import DynamicImage from '../components/ui/dynamicImage/dynamicImage';
import ModalCombiner from "../components/modalCombiner/modalCombiner"
import ContactForm from "../components/contact/contact"
import FullWidthContent from "../components/fullWidthContent/fullWidthContent"

const PyntePuder = () => {
  return (
    <Layout noHeaderImage={true}>
      <SEO title="Unikke Pyntepuder og Hynder" />

      <FullWidth
        starter="Starter"
        imagePosition="top"
        filename="tiles-black.jpg"
        alt="Pyntepude i sort stof"
      >
        <h1>Pyntepuder i unikt design</h1>
        <p>
          Boligtilbebør til hjemmet som specialsyede puder, giver din bolig et
          unikt look og er med til understøtte din personlige stil. Studio Gafni
          designer og syer pyntepuder, hynder og puder til præcis dit hjem.
          Kontakt os for en snak om puder speciallavet til din boligindretning.
        </p>
        <ModalCombiner />
      </FullWidth>

      <ImageContentBlock
        imagePosition="right"
        filename="process2.jpeg"
        alt="Syning af specialdesignede pyntepuder"
      >
        <h2>Specialsyede puder</h2>
      </ImageContentBlock>

      <ImageContentBlock
        imagePosition="left"
        filename="tiles in stol3.jpeg"
        alt="Pyntepude i lænestol fra FDB Møbler"
      >
        <h2>Boligtilbehør til dit hjem</h2>
      </ImageContentBlock>

      <FullWidth
        imagePosition="top"
        filename="Yellow-Musti-Medium.jpg"
        alt="Pyntepude i gult stof"
      >
        <h2>Former og farver tilpasset din indreting</h2>
        <p>
          Jeg hjælper dig med at matche de helt rigige farver og tekstiler til
          dit hjem, når vi går i gang med en dialog om unikke puder. I valget af
          boligtilbehør gør det rette valg af stoffer og mønstre en stor forskel
          i det samlede indtryk af dit hjems indreting. Specialsyede pyntepuder
          kan være med til at give jeres hjem et unikt look, som ingen andre
          har.
        </p>
      </FullWidth>

      <ImageContentBlock
        imagePosition="right"
        filename="HP5.jpg"
        alt="Pyntepude i gråt stof"
      >
        <h2>Puder syes i eget design</h2>
      </ImageContentBlock>

      <ImageContentBlock
        imagePosition="left"
        filename="bed3.jpeg"
        alt="Pyntepuder på en seng"
      >
        <h2>Puder i alle størrelser og former</h2>
      </ImageContentBlock>

      <FullWidthContent>
        <div
          style={{
            backgroundColor: "#F7F7F7",
            borderRadius: "10px",
            padding: "30px 0 30px 0",
          }}
        >
          <h2 style={{ textAlign: "center" }}>
            Kontakt mig for en snak, om puder til dit hjem:
          </h2>
          <ModalCombiner />
        </div>
      </FullWidthContent>

      <FullWidth
        imagePosition="top"
        filename="HP6.jpg"
        alt="Pyntepude i special design af Studio Gafni"
      >
        <h2>Komfort og kvalitet</h2>
        <p>
          Når jeg tager fat på et pudeprojekt, sætter jeg kvalitetsmaterialer i
          højsædet for at skabe en pude med god komfort og det rigtige udtryk
          til dit hjem. Jeg arbejder fra Frederiksberg og tager derfor gerne
          opgaver i hele København og Nordsjælland.
        </p>
      </FullWidth>

      <FullWidth
        imagePosition="top"
        filename="process1.jpeg"
        alt="Pyntepude syes"
      >
        <h2>Håndværk og design til dit hjem</h2>
        <p>
          {/* Jeg hedder Atar Gafni og ejer Studio Gafni. Jeg har en baggrund i møbelpolstring samt industriel
					design og tilbyder derfor dig en værdifuld kombination og æstetisk sans for design kombineret med
					praktisk håndværk, når møblerne skal ompolstres.
					<br /> <br />
					Når vi starter en dialog op, omkring ompolstring af dit møbel eller en speciallavet pude, lægger jeg
					vægt på, vi tager en god snak om dine ønsker for opgaven og de materialer, der skal benyttes under
					arbejdet.
					<br /> <br /> */}
          Du er meget velkommen til at kontakte mig for en uforpligtende snak
          gennem kontaktformularen her:
        </p>
      </FullWidth>

      <FullWidthContent>
        <div style={{ maxWidth: "450px", margin: "0 auto" }}>
          <h2 style={{ textAlign: "left" }}>
            Kontakt mig helt uforpligtende her:
          </h2>
          <ContactForm />
        </div>
      </FullWidthContent>
    </Layout>
  )
}

export default PyntePuder
